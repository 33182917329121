<template>
  <div v-show="isFootVisible" class="ui-game__foot">
    <canvas ref="canvas" width="160" height="320"></canvas>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue';
import { store } from '@/components/ui-game/store';
import { Foot } from '@/enums';
import { addLeadingZero, hexToColor } from '@/utils';

const data = [
  "00:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "01:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "02:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "03:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "04:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "05:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "06:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "07:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "08:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "09:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "0A:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "0B:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "0C:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "0D:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "0E:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "0F:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "10:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "11:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "12:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "13:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "14:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "15:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "16:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "17:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "18:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "19:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "1A:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "1B:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "1C:9:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "1D:6:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "1E:4:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
  "1F:F:00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00",
];

const canvas = ref(null);

const parse = (value) => {
  const [row] = value.split(':');
  const rowInfo = addLeadingZero(row).toLowerCase();
  const currentRowIndex = data.findIndex((record) => addLeadingZero(record.split(':')[0]).toLowerCase() === rowInfo);

  if (currentRowIndex !== -1) {
    data.splice(currentRowIndex, 1, value);
  }

  render(data);
};

const parseHexString = (hex) => {
  const result = [];
  let i = 0;

  while (i < hex.length) {
    const current = hex[i];
    const next = hex[i + 1];

    if (current && !next) {
      result.push(parseInt(`${current}`, 16));
      i += 1;
    } else if (current === '0' && next === '0') {
      result.push(0);
      i += 1;
    } else if (current === '0' && next !== '0') {
      if (hex[i + 2] && hex[i + 2] !== '0') {
        result.push(0);
        i += 1;
      } else {
        result.push(parseInt(`${current}${next}`, 16));
        i += 2;
      }
    } else if(current !== '0') {
      result.push(parseInt(`${current}${next}`, 16));
      i += 2;
    }
  }

  return result;
}

const getValues = (str) => {
  if (str.length === 32) {
    return str.match(/.{1,2}/g);
  }

  return parseHexString(str);
};

const render = (data) => {
  const ctx = canvas.value.getContext('2d');

  data.forEach((record) => {
    const [rowInfo, , sensorValues] = record.split(':');
    const row = parseInt(rowInfo, 16);
    const str = sensorValues.replace(/\s/g, '');
    const values = getValues(str);

    for (let column = 0; column < Foot.COLUMNS; column++) {
      const sensorValue = values[column];

      ctx.fillStyle = hexToColor(sensorValue);
      ctx.fillRect(column * Foot.CELL_SIZE, row * Foot.CELL_SIZE, Foot.CELL_SIZE, Foot.CELL_SIZE);
    }
  });
};

const isFootVisible = computed(() => store.state.isPlaying || store.state.isPaused);

onMounted(() => {
  render(data);

  window.btReceived = (value) => {
    if (!Array.isArray(value)) {
      parse(value);
    } else {
      value.forEach(parse);
    }

    const [, key] = (Array.isArray(value) ? value[0] : value).split(':');
    window.keyPressed(key, true);
  };
});
</script>
